import { Divider } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import InnovationHero from "../components/innovation/hero"
import HowSlide from "../components/innovation/howSlide"
import InnovLayout from "../components/innovation/innovLayout"
import IntroSlide from "../components/innovation/introSlide"
import ModulesSection from "../components/innovation/modulesSection"
import ScenarioSection from "../components/innovation/scenarioSection"
import Seo from "../components/seo"

const InnovationGuide = ({ pageContext: { originalPath } }) => {
  const { t } = useTranslation()

  return (
    <InnovLayout originalPath={originalPath}>
      <Seo title={t("innovation")} />
      <InnovationHero />
      <Divider />
      <IntroSlide />
      <Divider />
      <HowSlide />
      <Divider sx={{ display: { xs: "block", md: "none" }, pt: 12 }} />
      <ScenarioSection />
      <ModulesSection />
    </InnovLayout>
  )
}

export default InnovationGuide

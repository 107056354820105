import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const InnovationHero = () => {
  return (
    <Box
      component="section"
      pt={{ xs: 5, md: 12, xl: 20 }}
      pb={{ xs: 8, md: 18, xl: 30 }}
    >
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box maxWidth={{ xs: 272, md: 480, xl: 720 }} px={2}>
            <StaticImage
              src="../../images/innov-guide-logo.png"
              alt="Gender Innovation Guide logo"
              placeholder="tracedSVG"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="flex-end" height="100%">
            <Stack spacing={{ xs: 3, md: 6 }}>
              <Typography variant="h1">
                Gender
                <br />× Innovation
                <br />
                Guide
              </Typography>
              <Typography>
                A free digital guide to understand
                <br />
                gender in innovation and create
                <br />
                transformative impact for all.
              </Typography>
              <Box>
                <AnchorLink to="/innovation#intro">
                  <Button variant="outlined" endIcon={<ArrowDownwardIcon />}>
                    Get Started
                  </Button>
                </AnchorLink>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default InnovationHero
const modulesContent = [
  {
    number: 1,
    chapter: 1,
    title: "Gender Basics",
    blurb:
      "What foundational knowledge is needed before running an innovation challenge with a gender lens",
    what: "This is a collection of activities to develop a basic, or deeper understanding of gender transformation at various moments during the innovation process",
    when: "When you’re considering to host an innovation challenge and are aiming for a gender transformative process and outputs",
    imgName: "module01",
    keyword: "Understand",
    slide: "13",
  },
  {
    number: 2,
    chapter: 1,
    title: "Organization Basics",
    blurb:
      "How to strengthen the enabling environment in an organization to do gender transformative work",
    what: "This is a self-assessment activity to surface and reflect on the strengths and growth opportunities of your organization to enable gender transformative work.",
    when: "When you’re considering to host an innovation challenge and are looking for insights into your organizational baseline",
    imgName: "module02",
    keyword: "Nurture",
    slide: "41",
  },
  {
    number: 3,
    chapter: 1,
    title: "Innovation Challenge Basics",
    blurb:
      "How to use an innovation challenge as a vehicle for gender transformative outcomes",
    what: "This is a team activity to understand and align on the intentions behind wanting to host an innovation challenge, and reflect on what should be true for it to be the right vehicle towards transformative outcomes.",
    when: "When you’re considering to host an innovation challenge and need a gut check and internal alignment on the 'why'",
    imgName: "module03",
    keyword: "Transform",
    slide: "50",
  },
  {
    number: 4,
    chapter: 2,
    title: "Build your intersectional portrait",
    blurb: "How to uncover the ways gender inequality affects your users",
    what: "This is a participatory activity that helps to identify the different identities of your users and their needs - and recognize how those differences may also be reinforced by gender, class, race, caste, ethnicity or age. It helps surface barriers and identify consequences that can be addressed in your innovation.",
    when: "Use this when you want to understand your users, their different experiences with your innovation and the different considerations you need to make for them.",
    imgName: "module04",
    keyword: "Reflect",
    slide: "61",
  },
  {
    number: 5,
    chapter: 2,
    title: "Conduct a landscape assessment",
    blurb:
      "How the context and environment of your users influence their opportunities, behaviors and aspirations",
    what: "This is a participatory team activity to understand the context around your target users, and how that might enable or inhibit their behaviors.",
    when: "Use this when you want to understand your users' context in order to come up with your focus area.",
    imgName: "module05",
    keyword: "Define",
    slide: "70",
  },
  {
    number: 6,
    chapter: 3,
    title: "Set your target goal",
    blurb:
      "What gender-focused outcomes are you hoping to achieve through your innovation challenge?",
    what: "This is a co-creation activity to explore and align on your innovation challenge goals to ensure that your team and stakeholders are working towards a common vision and definition of success.",
    when: "Use this when refining the focus area imagining what success looks like for your innovation.",
    imgName: "module06",
    keyword: "Envision",
    slide: "80",
  },
  {
    number: 7,
    chapter: 3,
    title: "Make the case",
    blurb:
      "How to build support among your team and leadership to run a gender transformative innovation challenge",
    what: "This is an activity to better understand who you want to get support from and how to craft a narrative that is aligned with their motivations.",
    when: "Use this when preparing to get support for your inclusive innovation.",
    imgName: "module07",
    keyword: "Align",
    slide: "91",
  },
  {
    number: 8,
    chapter: 3,
    title: "Build your participant support journey",
    blurb: "How to provide adequate support and care to participants",
    what: "This is a participatory activity that will guide you through a series of steps to plan the right support on your challenge and get started on the right foot.",
    when: "Use this when setting up support structures for your users.",
    imgName: "module08",
    keyword: "Support",
    slide: "100",
  },
  {
    number: 9,
    chapter: 3,
    title: "Targeted outreach for more inclusive innovation",
    blurb:
      "Who is not at the table in relation to representation, voice and decision-making in your innovation process?",
    what: "Understanding who are the types of participants you want to join  in your innovation challenge and how to engage them.",
    when: "Use this when planning an innovation challenge, pre-launch. ",
    imgName: "module09",
    keyword: "Seek",
    slide: "108",
  },
  {
    number: 10,
    chapter: 3,
    title: "Inclusive Resource Allocation",
    blurb:
      "How to allocate resources for your innovation challenge with an inclusive lens",
    what: "This is a team activity to analyse the situations of the target challenge participants and ensure their needs are considered and included in the challenge budget.",
    when: "Use this while planning the challenge budget.",
    imgName: "module10",
    keyword: "Provide",
    slide: "118",
  },
  {
    number: 11,
    chapter: 4,
    title: "Monitor & Evaluate",
    blurb:
      "How to measure progress towards your goals through a set of indicators and collecting reliable and unbiased data",
    what: "To monitor and assess the impact of your activities, including an understanding of which indicators to use to measure gender transformation.",
    when: "Use this when planning  and preparing for your innovation challenge.",
    imgName: "module11",
    keyword: "Monitor",
    slide: "132",
  },
  {
    number: 12,
    chapter: 4,
    title: "Create a culture of feedback and accountability",
    blurb:
      "How to capture feedback and keep participants and yourself accountable to the outcomes",
    what: "This is a set of activities to plan and build an internal feedback structure that encourages continuous learning and creates a process that’s open to flexibility and adaptation.",
    when: "Use this during/after an innovation challenge,  looking for ways to improve or iterate on your challenge, or providing support to participants looking for feedback.",
    imgName: "module12",
    keyword: "Feedback",
    slide: "142",
  },
  {
    number: 13,
    chapter: 5,
    title: "Craft your impact story",
    blurb: "How to communicate impact and learning with the sector",
    what: "This is a worksheet to consolidate and craft a succinct, comprehensive and inclusive impact story.",
    when: "Use this at the end of an innovation challenge after impact has been monitored and evaluated.",
    imgName: "module13",
    keyword: "Share",
    slide: "151",
  },
  {
    number: 14,
    chapter: 5,
    title: "Create a long-term plan of action",
    blurb:
      "How to think about sustaining gender transformative innovation for the long-term",
    what: "This is an activity to evaluate how gender transformative an innovation is in terms of long-term impact and sustainability.",
    when: "Use this when reviewing an innovation or when trying to understand the long-term gender transformative impact of an innovation.",
    imgName: "module14",
    keyword: "Sustain",
    slide: "160",
  },
]

export default modulesContent
import { Box, Button, Typography } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const chapterBlockContent = [
  {
    number: 1,
    title: "Foundation",
    blurb:
      "Foundational knowledge, frameworks for understanding how gender shows up in innovation processes.",
    top: { xs: "5%", sm: "10%", md: "6%", lg: "10%", xl: "-8%" },
    left: { xs: "65%", md: "67%", lg: "65%", xl: "67%" },
  },
  {
    number: 2,
    title: "Assess",
    blurb:
      "Entry points for organizations and individuals to get started on their journey.",
    top: "50%",
    left: { xs: "80%", md: "73%" },
  },
  {
    number: 3,
    title: "Plan",
    blurb:
      "Ways for organizations to start defining their gender targets and integrating gender into challenge.",
    top: { xs: "83%", sm: "88%", md: "82%", lg: "85%", xl: "94%" },
    left: { xs: "45%", sm: "48%" },
  },
  {
    number: 4,
    title: "Implement & Track Change",
    blurb:
      "Ways for teams to support participants, track the progress and learn from each other.",
    top: "50%",
    left: { xs: -20, sm: "5%", md: "2%", lg: "7%" },
    width: 400,
  },
  {
    number: 5,
    title: "Review & Sustain",
    blurb:
      "Ways for organizations to reflect and share learnings and sustain the initiative for long term.",
    top: { xs: "10%", md: "10%", lg: "16%", xl: "10%" },
    left: { xs: "5%", sm: "20%", md: "10%", lg: "12%" },
  },
]

const StyledButton = ({ children, anchor }) => (
  <AnchorLink to={`/innovation#${anchor}`}>
    <Button variant="outlined" endIcon={<ArrowForwardIcon />}>
      {children}
    </Button>
  </AnchorLink>
)

const HowSlide = () => {
  return (
    <Box pt={5}>
      <Typography variant="h2" gutterBottom>
        Here's how it works
      </Typography>
      <Box maxWidth={790} pb={5}>
        <Typography variant="body2">
          Build your gender muscle with the help of the following learning
          modules. Each module asks a key question, and a number of activities
          that can be completed to attempt to answer to the question and access
          additional resources to dig deeper. Follow the steps on the wheel or
          choose your own adventure from the menu of cards.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box
          position="relative"
          width={{ xs: 310, sm: 536, md: 836, lg: 1136, xl: 1759 }}
          height={{ xs: 280, sm: 400, md: 600, lg: 720, xl: 860 }}
        >
          <Box
            position="absolute"
            top="50%"
            left="50%"
            sx={{ transform: "translate(-50%, -50%)" }}
            width={{ xs: 155, sm: 280, md: 340, lg: 420, xl: 680 }}
          >
            <StaticImage
              src="../../images/transformative-cycle.png"
              alt="Transformative cycle"
            />
          </Box>
          <Box
            width="25%"
            position="absolute"
            top="50%"
            left="50%"
            sx={{ transform: "translate(-50%, -50%)" }}
          >
            <Typography
              variant="h3"
              align="center"
              fontSize={{ xs: 12.86, sm: 24, md: 28, lg: 36, xl: 40 }}
            >
              Key steps for Gender Transformative impact
            </Typography>
          </Box>
          {chapterBlockContent.map(
            ({ number, title, blurb, top, left, width }) => (
              <Box
                key={title}
                maxWidth={{ xs: 110, md: 240, lg: 280, xl: 400 }}
                position="absolute"
                top={top || "50%"}
                left={left || "50%"}
                sx={{
                  "& .MuiTypography-body2": {
                    fontSize: { xs: 16, md: 16, xl: 24 },
                  },
                }}
              >
                <Typography
                  fontSize={{ xs: 11, md: 16, xl: 24 }}
                  variant="overline"
                  lineHeight={{ xs: "100.5%", md: "169.5%" }}
                  whiteSpace="nowrap"
                >
                  Chapter {number}
                </Typography>
                <Typography
                  fontSize={{ xs: 16, md: 20, xl: 36 }}
                  fontWeight={700}
                  variant="h4"
                  lineHeight={{ xs: "100.5%" }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="body2"
                  fontSize={12}
                  display={{ xs: "none", md: "block" }}
                >
                  {blurb}
                </Typography>
              </Box>
            )
          )}
        </Box>
      </Box>
      <Box textAlign="end" pt={6}>
        <StyledButton anchor="scenarios">Browse by Scenario</StyledButton>
        <Box pt={2}>
          <StyledButton anchor="modules">Browse All Modules</StyledButton>
        </Box>
      </Box>
    </Box>
  )
}

export default HowSlide
import { Box, Divider, Grid, Typography } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const IntroSlide = () => {
  return (
    <Grid container id="intro" sx={{ py: 6 }}>
      <Grid item xs={12} sm={9}>
        <Typography variant="h2" gutterBottom>
          Here to help you apply a gender lens to your innovation
        </Typography>
        <Typography>
          This interactive guide is designed to support innovators (like
          yourself!) to create a positive innovation culture and help people
          inside and outside of organizations break barriers, come together,
          generate new ideas, and iterate for gender transformative change. It
          is the culmination of learnings shared by stakeholders of the
          Generation Equality movement, technology companies, innovation
          challenge organizers, challenge participants, and more.
        </Typography>
        <Box pt={{ xs: 3, md: 7 }} pb={{ xs: 2, md: 8, xl: 20 }}>
          <Grid container>
            <Grid item xs={5} md={4}>
              <StaticImage
                src="../../images/GenerationEquality-notag-En-RGB.png"
                alt="Generation Equality logo"
                placeholder="tracedSVG"
              />
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderWidth: { xs: 0.5, md: 1.5 } }}
              />
            </Grid>
            <Grid item xs={6} md={7}>
              <Box
                sx={{
                  "& .MuiTypography-body1": {
                    fontSize: { xs: 11, md: 20, xl: 29 },
                    fontWeight: 500,
                  },
                }}
              >
                <Typography sx={{ maxWidth: 420 }}>
                  Action Coalition on
                  <br />
                  Technology and Innovation
                  <br />
                  for Gender Equality
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default IntroSlide
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles"
import React from "react"
import getChapterColor from "./getChapterColor"
import ModuleCard from "./moduleCard"
import useModulesContent from "./useModulesContent"

const cardSize = {xs: 212, sm: 228, md: 244, lg: 260, xl: 279}

const getCardHeight = width => (width * 436) / 295

const cardHeight = {
  xs: getCardHeight(cardSize.xs),
  sm: getCardHeight(cardSize.sm),
  md: getCardHeight(cardSize.md),
  lg: getCardHeight(cardSize.lg),
  xl: getCardHeight(cardSize.xl),
}

const ArrowHead = ({ chapterNum, sx }) => (
  <Box width="100%" position="relative">
    <Box
      sx={{
        position: "absolute",
        borderTop: `3px solid ${getChapterColor(chapterNum)}`,
        borderRight: `3px solid ${getChapterColor(chapterNum)}`,
        height: 15,
        width: 15,
        ...sx
      }}
    />
  </Box>
)

const UpArrowHead = ({ chapterNum }) => (
  <ArrowHead
    chapterNum={chapterNum}
    sx={{
      transform: "rotate(45deg)",
      right: 0,
      top: -41,
    }}
  />
)


const DownArrowHead = ({ chapterNum }) => (
  <ArrowHead
    chapterNum={chapterNum}
    sx={{
      transform: "rotate(135deg)",
      left: -9,
      bottom: -25,
    }}
  />
)

const OffsetCard = ({ offset, chapterNum }) => (
  <Box
    position="absolute"
    width={"100%"}
    height={ cardHeight }
    border={`2px solid ${getChapterColor(chapterNum)}`}
    borderRadius={{ xs: 5.5, md: 8 }}
    top={{ xs: offset * 0.75 , md: offset }}
    left={{ xs: offset * 0.75 , md: offset }}
    bgcolor="#000"
  />
)

const StackedCards = ({ moduleNum, chapterNum }) => (
  <Box position="relative" width={cardSize} >
    <OffsetCard offset={20} chapterNum={chapterNum} />
    <OffsetCard offset={10} chapterNum={chapterNum} />
    <ModuleCard moduleNum={moduleNum} />
  </Box>
)

const ChapterBlock = ({ chapterNum, title }) => {
  const modulesData = useModulesContent().filter(
    ({ chapter }) => chapter === chapterNum
  )

  const theme = useTheme()
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  
  return (
    <Box
      pb={3}
      mt={5}
      borderLeft={{
        xs: `3px solid ${getChapterColor(chapterNum)}`,
        md: "none",
      }}
    >
      <Box pb={2} pl={3}>
        <Typography variant="overline">Chapter {chapterNum}</Typography>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box pl={{ xs: 3, md: 4 }} pr={{ xs: "25%", sm: 0 }}>
        <Grid container spacing={4}>
          {modulesData.map(({ number, what, when }, index) => (
            <Grid
              key={number}
              item
              xs={12}
              lg={6}
              xl={4}
              sx={{
                borderTop: {
                  xs: "none",
                  md: `3px solid ${getChapterColor(chapterNum)}`,
                },
                mt: { xs: 0, md: 4 },
                mb: { xs: 4, md: 10 },
              }}
            >
              <Box display={{ xs: "none", md: "block" }}>
                {(index >= modulesData.length - 1 ||
                  (index + 1) % (xlUp ? 3 : lgDown ? 1 : 2) === 0) && (
                  <UpArrowHead chapterNum={chapterNum} />
                )}
              </Box>
              <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                <StackedCards moduleNum={number} chapterNum={chapterNum} />
                <Box flexBasis={{ xs: "100%", sm: "50%" }}>
                  <Stack
                    spacing={2}
                    sx={{ pl: { sx: 0, sm: 6 }, pr: 4, pt: { xs: 5, sm: 0 } }}
                  >
                    <Box>
                      <Typography variant="h6">What</Typography>
                      <Typography>{what}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">When</Typography>
                      <Typography>{when}</Typography>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box display={{ xs: "block", md: "none" }}>
        <DownArrowHead chapterNum={chapterNum} />
      </Box>
    </Box>
  )}

const ModulesSection = () => {

  return (
    <Box id="ModulesSection" pt={{ xs: 0, md:10, xl: 20 }}>
      <Typography variant="h2" id="modules">
        All Modules
      </Typography>
      <ChapterBlock chapterNum={1} title="Foundation" />
      <ChapterBlock chapterNum={2} title="Assess" />
      <ChapterBlock chapterNum={3} title="Plan" />
      <ChapterBlock chapterNum={4} title="Implement & Track Change" />
      <ChapterBlock chapterNum={5} title="Review & Sustain" />
    </Box>
  )
}

export default ModulesSection
import { Box, Button, Typography } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import ReactCardFlip from "react-card-flip"
import AspectRatioBox from "../../utils/aspect-ratio-box"
import getChapterColor from "./getChapterColor"
import useModulesContent from "./useModulesContent"

const deckBaseUrl = `https://docs.google.com/presentation/d/1wvXBckfpfpms-LVs8gkCsRaVftcINjBe`

const ModuleCard = ({moduleNum, zIndex}) => {
  
  const { chapter, title, blurb, cardImg, keyword, slide } =
    useModulesContent(moduleNum)
  
  const [isFlipped, setIsFlipped] = useState(false)

  const handleClick = () =>
    // Handle click on touch screens
    {
      if (isFlipped) {
        window.open(`${deckBaseUrl}/edit#slide=id.p${slide}`, "_blank", "noopener,noreferrer")
        setIsFlipped(false)
      } else {
        setIsFlipped(true)
      }
    }

  return (
    <Button fullWidth sx={{ height: "100%" }} onClick={handleClick}>
      <Box
        width="100%"
        height="100%"
        onMouseLeave={() => setIsFlipped(false)}
        onMouseEnter={() => setIsFlipped(true)}
        zIndex={zIndex}
        position="relative"
      >
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
          <AspectRatioBox ratio={436 / 295}>
            <Box
              id="CardBack"
              sx={{
                p: "3px",
                bgcolor: getChapterColor(chapter),
                borderRadius: { xs: 5.5, md: 8 },
                height: "100%",
                width: "100%",
              }}
            >
              <Box
                bgcolor="#FFF"
                sx={{
                  borderTopLeftRadius: { xs: 22, md: 32 },
                  borderTopRightRadius: { xs: 22, md: 32 },
                  p: { xs: 1, md: 2 },
                }}
                height="80%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <GatsbyImage image={cardImg} alt="Card open" />
              </Box>
              <Box
                height="20%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="subtitle1" align="center" /* py={3} */>
                  {keyword}
                </Typography>
              </Box>
            </Box>
          </AspectRatioBox>
          <AspectRatioBox ratio={436 / 295}>
            <Box
              id="CardFace"
              sx={{
                height: "100%",
                width: "100%",
                border: 3,
                borderColor: getChapterColor(chapter),
                borderRadius: { xs: 5.5, md: 8 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#000",
              }}
            >
              <Box
                sx={{
                  px: 3,
                  py: 5,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  textAlign: "center",
                }}
              >
                <Typography variant="overline">{`Module ${moduleNum}`}</Typography>
                <Typography variant="h5">{title}</Typography>
                <Typography variant="body2">{blurb}</Typography>
                <Box fontSize={36} lineHeight={0.5}>
                  →
                </Box>
              </Box>
            </Box>
          </AspectRatioBox>
        </ReactCardFlip>
      </Box>
    </Button>
  )
}

export default ModuleCard
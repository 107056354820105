import { graphql, useStaticQuery } from "gatsby"
import modulesContent from "./modulesContent"

const useModulesContent = (moduleNum) =>{
  const { allFile } = useStaticQuery(
    graphql`
      {
        allFile(filter: { relativeDirectory: { eq: "modules" } }) {
          nodes {
            name
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
      }
    `
  )

  const getCardImg = (cardName) => {
    return allFile.nodes.find(({ name }) => name === cardName)
  }
  
  const processedContent = modulesContent.map(
    ({
      number,
      chapter,
      title,
      blurb,
      what,
      when,
      imgName,
      keyword,
      slide,
    }) => ({
      number,
      chapter,
      title,
      blurb,
      what,
      when,
      cardImg: getCardImg(imgName).childImageSharp.gatsbyImageData,
      keyword,
      slide,
    })
  )
  
  return moduleNum
    ? processedContent.find(module => module.number === moduleNum)
    : processedContent
} 

export default useModulesContent
import * as React from "react"
import Header from "../header"
import { Box, Container, createTheme, GlobalStyles, ThemeProvider } from "@mui/material"
import InnovFooter from "./innovFooter"

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1823,
    },
  },
})

const cardBackStyle = {
  "& .MuiTypography-subtitle1": {
    color: "#000",
    fontFamily: "Vollkorn SC",
    textTransform: "lowercase",
    letterSpacing: "0.42em",
    fontSize: {xs: 18, md: 20, xl: 25.191},
  },
}

const cardFaceStyle = {
  "& .MuiTypography-h5": {
    fontFamily: "Caudex",
    textTransform: "none",
    fontSize: { xs: 24, md: 30, xl: 38 },
    lineHeight: 1,
  },
  "& .MuiTypography-body2": {
    fontFamily: "Vollkorn",
    textTransform: "none",
    fontSize: { xs: 16, md: 18, lg: 22 },
    lineHeight: "21px",
  },
  "& .MuiTypography-overline": {
    fontSize: 15.38,
    lineHeight: 1,
    textTransform: "lowercase",
  },
}

const innovTheme = {
  bgcolor: "#000000",
  "& .MuiTypography-root": {
    color: "#FFFFFF",
    fontFamily: "Syne",
  },
  "& .MuiContainer-root": {
    px: 4,
  },
  "& .MuiTypography-body1": {
    fontSize: { xs: 16, md: 24, xl: 40 },
    lineHeight: 1.5,
    fontWeight: 400,
  },
  "& .MuiTypography-body2": {
    fontSize: { xs: 16, md: 20, xl: 24 },
    lineHeight: { xs: 1.2, xl: 1.5 },
  },
  "& .MuiTypography-h1": {
    fontFamily: "Caudex",
    fontSize: { xs: 46, md: 60, xl: 85 },
    lineHeight: 1.015,
    fontWeight: 400,
  },
  "& .MuiTypography-h2": {
    fontSize: { xs: 32, md: 54, xl: 80 },
    lineHeight: "126.5%",
    fontWeight: 500,
  },
  "& .MuiTypography-caption": {
    fontSize: "5rem",
    lineHeight: "126.5%",
  },
  "& .MuiButtonBase-root": {
    borderColor: "#FFFFFF",
    color: "#FFFFFF",
    borderRadius: { xs: 4, md: 6 },
    borderWidth: { xs: 1, lg: 2 },
    fontFamily: "Syne",
    fontSize: { xs: 14, md: 20, xl: "1.5rem" },
    letterSpacing: "0.2em",
    py: { xs: 1, md: 1.5, xl: 2 },
    px: { xs: 2, md: 3, xl: 4 },
    ":hover": {
      borderWidth: 2,
    },
  },
  "& .MuiDivider-root": {
    borderColor: "#FFFFFF",
  },
  "& #ScenariosSection": {
    "& .MuiTypography-h2": {
      fontSize: { xs: 34.32, md: 44, xl: 60 },
      fontWeight: 400,
      fontFamily: "Caudex",
      lineHeight: "96.91%",
    },
    "& .MuiTypography-h3": {
      fontSize: { xs: 18, sm: 28, md: 36, xl: 42 },
      fontWeight: 400,
      fontFamily: "Vollkorn",
    },
    "& .MuiTypography-h4": {
      fontSize: { xs: 36, md: 42, xl: 49.56 },
      fontFamily: "Vollkorn SC",
      textTransform: "lowercase",
    },
    "& .MuiTypography-body2": {
      fontSize: { xs: 12.58, sm: 18, md: 20, xl: 22 },
      lineHeight: "21px",
    },
    "& .MuiTypography-overline": {
      fontFamily: "Vollkorn SC",
      fontSize: { xs: 10.2959, sm: 14, md: 18 },
      letterSpacing: "0.52rem",
      lineHeight: "96.91%",
      textTransform: "lowercase",
    },
    "& .MuiButtonBase-root": {
      p: 0,
    },
    "& #CardFace": cardFaceStyle,
    "& #CardBack": cardBackStyle,
  },
  "& #ModulesSection": {
    "& .MuiTypography-h2": {
      fontSize: { xs: 34.32, md: 44, xl: 60 },
      fontWeight: 400,
      fontFamily: "Caudex",
    },
    "& .MuiTypography-overline": {
      fontFamily: "Vollkorn SC",
      fontSize: 18,
      letterSpacing: "0.52rem",
      lineHeight: "21px",
      textTransform: "lowercase",
    },
    "& .MuiTypography-h3": {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "28px",
    },
    "& .MuiTypography-h6": {
      fontSize: { xs: 14, sm: 17 },
      fontWeight: 700,
      lineHeight: "19px",
      textTransform: "uppercase",
    },
    "& .MuiTypography-body1": {
      fontSize: { xs: 14, sm: 17 },
      lineHeight: 1.1,
    },
    "& .MuiButtonBase-root": {
      p: 0,
    },
    "& #CardFace": cardFaceStyle,
    "& #CardBack": cardBackStyle,
  },
  header: {
    bgcolor: "#000",
    "& .MuiButton-root": {
      fontSize: "1rem",
      px: 2,
      letterSpacing: 0,
    },
  },
  footer: {
    "& .MuiTypography-body1": {
      fontSize: {xs: 16, xl: 22},
      lineHeight: 1,
    },
  },
}

const InnovLayout = ({ children, originalPath }) => {

  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyles styles={{ a: { textDecoration: "none" } }} />
      <Box sx={innovTheme}>
        <div>
          <header>
            <Header originalPath={originalPath} />
          </header>
          <main>
            <Container maxWidth="xl">{children}</Container>
          </main>
          <footer>
            <InnovFooter />
          </footer>
        </div>
      </Box>
    </ThemeProvider>
  )
}

export default InnovLayout

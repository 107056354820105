const getChapterColor= (chapterNum) => {
  const chapterColors = [
    {number: 1, color: "#EBE80D"},
    {number: 2, color: "#FF21B8"},
    {number: 3, color: "#82F266"},
    {number: 4, color: "#9A00FF"},
    {number: 5, color: "#00B8FF"},
  ]
  return chapterColors.find(({ number }) => number === chapterNum).color
}

export default getChapterColor
import { Box } from "@mui/material"
import React from "react"

const AspectRatioBox = ({ ratio, children }) => {
  return (
    <Box position="relative" pb={`${ratio * 100}%`} height={0}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "10px",
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default AspectRatioBox
import { Box, Container, Divider, Grid, Typography } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import CloseIcon from "@mui/icons-material/Close"

const CreditBox = ({ title, children }) => (
  <Box display="flex" flexDirection="column" height="100%">
    <Typography>{title}</Typography>
    <Box display="flex" alignItems="center" flexGrow={1} pt={{ xs: 2, md: 5 }}>
      {children}
    </Box>
  </Box>
)

const InnovFooter = () => {
  return (
    <Container maxWidth="xl" sx={{ mt: 10 }}>
      <Divider />
      <Box pt={3} pb={8}>
        <Grid container spacing={8} columns={{ xs: 4, sm: 8, md: 24 }}>
          <Grid item xs={12} md={7}>
            <CreditBox title="A project in collaboration with">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  sx={{ display: "flex", alignItems: "flex-end" }}
                >
                  <StaticImage
                    src="../../images/ideo-logo.png"
                    alt="IDEO.org logo"
                    placeholder="tracedSVG"
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <CloseIcon sx={{ color: "#fff" }} />
                </Grid>
                <Grid item xs={10} lg={4}>
                  <Box maxWidth={200}>
                    <StaticImage
                      src="../../images/UNW_logo_white.png"
                      alt="UN Women logo"
                      placeholder="tracedSVG"
                    />
                  </Box>
                </Grid>
              </Grid>
            </CreditBox>
          </Grid>
          <Grid item xs={12} md={5}>
            <CreditBox title="Developed with the support of">
              <Box maxWidth={277}>
                <StaticImage
                  src="../../images/Finland.png"
                  alt="Govt of Finland logo"
                  placeholder="tracedSVG"
                />
              </Box>
            </CreditBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CreditBox title="Co-authored and co-designed by">
              <Typography>
                The Leaders of the Action Coalition on Technology and Innovation
                for Gender Equality
              </Typography>
            </CreditBox>
          </Grid>
          <Grid
            item
            xs={false}
            md={2}
            sx={{ display: { xs: "none", lg: "block" } }}
          />
          <Grid item xs={12} md={4}>
            <Box
              display="flex"
              alignItems="flex-end"
              height="100%"
              maxWidth={240}
            >
              <StaticImage
                src="../../images/GenerationEquality-notag-En-RGB.png"
                alt="Generation Equality logo"
                placeholder="tracedSVG"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
 export default InnovFooter

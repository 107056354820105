import { Box, Button, Grid, Slide, Typography } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import React, { useState } from "react"
import AspectRatioBox from "../../utils/aspect-ratio-box"
import ModuleCard from "./moduleCard"

const cardSize = {xs: 212, sm: 228, md: 244, lg: 260, xl: 279}

const ScenarioDeck = ({
  number,
  title,
  blurb,
  modules,
  expanded,
  setExpanded,
}) => {
  const { cardBackImg } = useStaticQuery(
    graphql`
      {
        cardBackImg: file(name: { eq: "card-down" }) {
          childImageSharp {
            gatsbyImageData(width: 295)
          }
        }
      }
    `
  )

  return (
    <Box
      borderTop={{
        xs: number > 1 ? "1px solid #fff" : 0,
        md: "1px solid #fff",
      }}
      pt={{ xs: number > 1 ? 8 : 3, md: 8 }}
      pb={8}
    >
      <Box pb={5}>
        <Typography variant="overline">Scenario {number}</Typography>
        <Typography variant="h3" pt={{ xs: 2, md: 4, lg: 6 }}>
          {title}
        </Typography>
        <Typography variant="body2" pt={{ xs: 1, md: 3 }}>
          {blurb}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item>
          <Box width={cardSize}>
            <BgImage image={getImage(cardBackImg)}>
              <AspectRatioBox ratio={436 / 295}>
                <Button
                  onClick={() =>
                    expanded === number
                      ? setExpanded(false)
                      : setExpanded(number)
                  }
                  fullWidth
                  sx={{ zIndex: 20, height: "100%" }}
                >
                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h4" color="white" align="center">
                      DECK {"I".repeat(number)}
                      <br />→
                    </Typography>
                  </Box>
                </Button>
              </AspectRatioBox>
            </BgImage>
          </Box>
        </Grid>
        {modules.map((module, index) => (
          <Slide
            key={index}
            direction="right"
            in={number === expanded}
            mountOnEnter
            unmountOnExit
          >
            <Grid item>
              <Box width={cardSize}>
                <ModuleCard moduleNum={module} zIndex={10 - index} />
              </Box>
            </Grid>
          </Slide>
        ))}
      </Grid>
    </Box>
  )
}

const decksContent = [
  {
    number: 1,
    title:
      "“We would like to integrate a gender lens into our existing innovation process”",
    blurb:
      "Eg. You are global technology hardware company working on improving your product development process to be more gender-inclusive",
    modules: [1, 4, 5, 7, 11],
  },
  {
    number: 2,
    title:
      "“We are considering running an innovation challenge for the first time and want to understand if it is the right vehicle”",
    blurb:
      "Eg. You are part of a govermental organization looking to run an open-innovation challenge in your country.",
    modules: [3, 6, 8, 9, 10],
  },
  {
    number: 3,
    title:
      "“We have organized innovation challenges before and want to evaluate if we are working towards gender transformation“",
    blurb:
      "Eg. You are a global non-profit looking to overhaul your innovation challenge practice",
    modules: [2, 4, 12, 13, 14],
  },
]

const ScenarioSection = () => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Box id="ScenariosSection" pt={{ xs: 6, md: 15, xl: 30 }}>
      <Typography id="scenarios" variant="h2" pb={{ xs: 0, md: 5.75 }}>
        Scenario Decks
      </Typography>
      {decksContent.map(({ number, title, blurb, modules }) => (
        <ScenarioDeck
          key={title}
          number={number}
          title={title}
          blurb={blurb}
          modules={modules}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      ))}
    </Box>
  )
}

export default ScenarioSection